import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ExternalLink = ({ data }) => {
  return (
    <a href={data.href} target="_blank" rel="noreferrer" className="ml-8 mr-8">
      <GatsbyImage
        className="w-12 md:w-24 h-full"
        alt={data.text}
        image={data?.logo?.gatsbyImage}
      />
    </a>
  )
}

export default ExternalLink

import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import Hero from "../components/hero";
import ExternalLink from "../components/external-link";

const Index = ({ data }) => {
  // alert(height);
  const heroReleases = data.allContentfulCatalog.edges;
  // const blogpostLatest = data.allContentfulBlogPost.edges[0].node;
  const blogPosts = data.allContentfulBlogPost.edges;

  const heroes = heroReleases.map((hr, i) => {
    return (
      <div className="mb-24 mr-8 md:mr-24" key={`hero-${i}`}>
        <Hero
          image={hr.node.cover[0]}
          title={hr.node.title}
          content={hr.node.blurb}
          audio={hr.node.audio}
          link={hr.node.url}
        />
      </div>
    );
  });

  const links = data.allContentfulExternalLink.edges.map((e, i) => {
    return <ExternalLink key={`link-${i}`} data={e.node}></ExternalLink>;
  });

  /* -mb-48 ensures second page takes up full viewport */
  let posts = blogPosts.map((data) => {
    let post = data.node;
    return (
      <div
        style={{ background: post.bgStyle?.background }}
        id="news"
        className={`p-8 sm:p-12 md:p-32 snap-center border-2 border-slate-200
 flex items-center justify-center ${
   post.bgStyle?.background ? "" : "bg-red-400"
 }`}
      >
        <div className="justify-center items-center">
          <Hero
            imageHeight="[calc(40vh)]"
            image={post.image}
            title={post.title}
            content={post.body}
            link={post.link}
          />
        </div>
      </div>
    );
  });

  return (
    <Layout location={data.location}>
      <Helmet>
        <meta
          http-equiv="ScreenOrientation"
          content="autoRotate:disabled"
        ></meta>
      </Helmet>
      <div className="snap-y snap-mandatory border-2 border-slate-200 flex flex-col">
        <div id="releases " className="snap-center border-2 ">
          <div className="hidden w-full absolute items-center fixed sm:justify-center sm:flex p-8 ">
            {links}
          </div>
          <div className="z-0 overflow-x-scroll overflow-y-hidden whitespace-nowrap h-[calc(100%)]">
            <div className="jusitfy-start flex flex-row p-8 sm:p-12 md:p-32 pt-8 pb-0">{heroes}</div>
          </div>
        </div>

        <>{posts}</>
      </div>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulCatalog(
      sort: { fields: [releaseDate], order: DESC }
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          featured
          id
          url
          blurb {
            raw
          }
          audio {
            file {
              contentType
              url
            }
            description
            title
          }
          title
          platforms
          format
          cover {
            title
            gatsbyImage(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              width: 1600
            )
            description
          }
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [date], order: DESC }
      filter: { slug: { ne: "donotuse" } }
    ) {
      edges {
        node {
          title
          slug
          bgStyle {
            background
          }
          date(formatString: "MMMM Do, YYYY")
          tags
          image {
            title
            file {
              url
              contentType
            }
            gatsbyImage(
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              width: 800
              formats: WEBP
            )
          }
          link
          body {
            raw
          }
          excerpt {
            excerpt
          }
        }
      }
    }
    allContentfulExternalLink {
      edges {
        node {
          text
          href
          logo {
            gatsbyImage(layout: FULL_WIDTH, placeholder: TRACED_SVG, width: 120)
            file {
              contentType
            }
          }
          id
          text
        }
      }
    }
  }
`;
